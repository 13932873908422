export default [
  { key: "no", label: "ลำดับที่", sortable: true , thStyle:{color:"white",width: "5% !important"}},
  {
    key: "fullname",
    label: "ชื่อ-สกุล",
    thStyle:{color:"white",textAlign: "center"}
  }, 
  {
    key: "username",
    label: "ชื่อผู้ใช้งาน",
    thStyle:{color:"white", textAlign: "center"}
  }, 
  {
    key: "email",
    label: "Email",
    thStyle:{color:"white",textAlign: "center"}
  }, 
  {
    key: "type",
    label: "ประเภทผู้ใช้งาน",
    thStyle:{color:"white",width: "8% !important",textAlign: "center"}
  }, 
  { key: "roleName", label: "กลุ่มสิทธิ์", sortable: true , thStyle:{color:"white",width: "8% !important",textAlign: "center"}}, 
  { key: "active", label: "สถานะ", sortable: true , thStyle:{color:"white",width: "8% !important",textAlign: "center"}},  
  {
    key: "action",
    label: "จัดการ",
    sortable: false,
    thStyle: { width: "12% !important", textAlign: "center", color:"white" },
  },
];
