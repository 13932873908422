<template>
  <b-row>
    <b-col cols="12">
      <b-card>
        <b-row>
          <b-col md="4" class="my-1">
            <h2><b>Manage users</b></h2>
          </b-col>
          <b-col md="8" class="my-1">
            <b-form inline class="d-flex justify-content-end">
              <b-input-group class="input-group-merge" style="width: 40%">
                <b-form-input
                  id="basic-password1"
                  type="text"
                  placeholder="Search..."
                  v-model="filter"
                />
                <b-input-group-append is-text>
                  <feather-icon icon="SearchIcon" class="cursor-pointer" />
                </b-input-group-append>
              </b-input-group>

              <b-button
                v-if="userPermission=='create' || userPermission=='edit'"             
                variant="primary"
                class="ml-1 shadow"
                @click="$router.push('/setting/user/create')"
                ><feather-icon icon="PlusCircleIcon" size="14" /> เพิ่มผู้ใช้งาน
              </b-button>
            </b-form>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12">
            
            <b-table
              style="font-size: 12px"
              class="shadow"
              small
              striped
              bordered
              hover
              responsive
              :per-page="perPage"
              :current-page="currentPage"
              :items="items"
              :fields="visibleFields"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
              :filter="filter"
              :filter-included-fields="filterOn"
              @filtered="onFiltered"
              :busy="isLoading"
            >
              <template #table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong> Loading...</strong>
                </div>
              </template>

              <template #cell(no)="row">
                <center>{{ row.index + 1 }}</center>
              </template>

              <template #cell(type)="row">
                <center>
                 {{ row.item.type == "customer" ? "ลูกค้า":"พนักงาน" }}
                </center>
              </template>

              <template #cell(roleName)="row">
                <center>
                  <b-badge
                    v-if="row.item.roleName"
                    pill
                    variant="success"
                    >{{ row.item.roleName }}
                  </b-badge>
                  <span v-else>-</span>
                </center>
              </template>

              <template #cell(active)="row">
                <center>
                  <span v-if="row.item.active == 1" class="text-primary">
                    เปิดใช้งาน</span
                  >
                  <span v-else class="text-danger"> ปิด</span>
                </center>
              </template>

              <template #cell(action)="row" v-if="userPermission=='create' || userPermission=='edit'">
                <center>
                  <b-button
                    variant="outline-secondary"
                    size="sm"
                    pill
                    @click="$router.push(`/setting/user/edit/${row.item.userId}`)"
                  >
                    <feather-icon icon="EditIcon" class="cursor-pointer" />
                  </b-button>

                  <b-button
                    variant="danger"
                    class="ml-1"
                    size="sm"
                    pill
                    @click="deleteUser(row.item.userId)"
                  >
                    <feather-icon icon="TrashIcon" class="cursor-pointer" />
                  </b-button>
                </center>
              </template>
            </b-table>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="2">
            <b-form-group class="mb-0">
              <label class="d-inline-block text-sm-left mr-50">Per page</label>
              <b-form-select
                id="perPageSelect"
                v-model="perPage"
                size="sm"
                :options="pageOptions"
                class="w-50"
              />
            </b-form-group>
          </b-col>

      

          <b-col cols="10" class="d-flex justify-content-end">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              align="center"
              size="sm"
              variant="secondary"
              class="my-0"
            />
          </b-col>
        </b-row>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BTable,
  BAvatar,
  BBadge,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BCard,
  BForm,
  BSpinner
} from "bootstrap-vue";
import vSelect from "vue-select";

import Field from "./field";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import UserService from "@/services/user";

export default {
  components: {
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCard,
    BForm,
    vSelect,
    BSpinner
  },
  data() {
    return {
      perPage: 1000,
      pageOptions: [1000, 2000, 5000],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      fields: Field,
      items: [],
      isLoading: false,
    };
  },

  computed:{
    userPermission () {
      const permission = this.$store.getters["user/getPermissionPage"]("ST005")
      if(permission=="disable"){
        localStorage.removeItem("userData")
        window.location.href="/not-authorized"
      }
      return permission         
    },
    visibleFields() {
        return this.fields.filter(field => {
          if(this.$store.getters["user/getPermissionPage"]("ST005") !== "edit"){
            return field.key !== "action"
          }
          return field
        })
    }
  },


  mounted() {
    this.getUsers();
    
  },
  methods: {
    async getUsers() {
      this.isLoading = true;
      const result = await UserService.GetUserList({},"all=true");
      this.items = result.data.data;
      this.totalRows = this.items.length;
      this.isLoading = false;
    },

    async deleteUser(id) {
      const isConfirm = confirm("ต้องการลบรายการนี้หรือไม่ ?");

      if (!isConfirm) {
        return false;
      }

      this.isLoading = true;
      const result = await UserService.DeleteUser(id);
      if (result.status == "success") {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `User`,
            icon: "CheckIcon",
            variant: "success",
            text: `ลบรายการเรียบร้อย`,
          },
        });
        await this.getUsers();
      } else {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `User`,
            icon: "XIcon",
            variant: "danger",
            text: `พบปัญหาในการลบรายการ`,
          },
        });
      }

      this.isLoading = false;
    },

    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>

<style scoped>
.table-hover tbody tr:hover {
  background-color: aqua !important;
}
</style>
